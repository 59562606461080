import { MEET_TYPES } from "constants/types";
import {
  actionFulfilled,
  SET_NEW_MEET_START,
  SET_NEW_MEET_END,
  GET_AVAILABLE_GOVERNING_BODYES,
  SET_MEET_DAYS,
  ADD_MEET_EVENTS,
  UPDATE_JUDGE_STATUS,
  NEW_MEET_DND_EVENT_REPLACE,
  SET_MEET_TYPE,
  REMOVE_EVENT,
  NEW_MEET_DND_EVENT_COMBINE,
  NEW_MEET_EVENT_UNCOMBINE,
  NEW_MEET_EVENT_REORDER,
  CUT_EXTRA_INVITED_JUDGES,
  SET_EVENT_ID_FOR_EDITING,
  CLEAR_EDITED_EVENT_ID,
  GET_AVAILABLET_ADMINS,
  SET_ADMINS_FOR_MEET,
  GET_TEAMS,
  GET_INVITED_TEAMS,
  CREATE_MEET_INIT,
  UPDATE_TEAMS_IN_NEW_MEET,
  GET_MEW_MEET_JUDGES_BY_SEARCH_KEY,
  UPDATE_CREATED_EVENT,
  ON_GB_RESET_FOR_NEW_MEET,
  CLEAR_NEW_MEET_DATA,
  SET_JUDGES_SCHEMA_ID_ON_NEW_MEET,
  REMOVE_SESSION_AT_CREATE_MEET,
  CREATE_TEAM_VENUE_INIT,
  GET_COUNTRY_ISO,
  SET_JUDGES,
  RESET_EVENTS,
  GET_NEW_MEET_JUDGES_BY_PAGE,
  UPDATE_DAY_WARM_UP_TIME,
  UPDATE_SESSION_WARM_UP_TIME,
  ADD_SEARCHED_VENUE_TO_LIST,
  GET_TEAMS_VENUES,
  GET_MEET_FOR_EDITING,
  SEARCH_TEAMS,
  CLEAR_SEARCH_TEAMS,
  SET_SELECTED_TEAMS,
  SET_MEET_INFO,
} from "store/actionTypes";
import { SWIPE_STATUS } from "constants/status";
import { DEFAULT_JUDGES_SCHEMA_ID } from "constants/settings";

import { getISODate } from "helpers/time";

const resetEventFields = {
  restTeams: [],
  intivedTeams: [],
  teamsCurrentPage: 0,
  meetDates: [],
  startDate: null,
  endDate: null,
  meetId: null,
  teams: [],
  selectedGoverningBody: "",
  mainTeam: {},
  judges: [],
  meetType: MEET_TYPES.DUAL,
  meetInfo: {},
  judgesSchemaId: DEFAULT_JUDGES_SCHEMA_ID,
  teamVenues: [],
  allVenues: [],
  venueDTO: {},
  countryISO: [],
  meetAdmins: {
    allUsers: [],
    selectedAdmins: [],
    adminsCurrentPage: 0,
  },
};

const initState = {
  ...resetEventFields,
  availableGoverningBodyes: [],
  eventIdForEditing: "",
};

const judgeCallback = (judge) => ({
  ...judge,
  name: `${judge.firstName} ${judge.lastName}`,
  invited: false,
  status: SWIPE_STATUS.NULL,
});

const sliceJudges = (oldJudges, newJudges) => {
  const invitedJudges = oldJudges.filter(
    ({ status }) => status === SWIPE_STATUS.INVITED,
  );
  const newJudgesWithoutInvited = newJudges
    .filter(
      ({ id: newJudgeId }) =>
        !invitedJudges.some(({ id }) => id === newJudgeId),
    )
    .map(judgeCallback);
  return [...invitedJudges, ...newJudgesWithoutInvited];
};

const replaceEvent = (oldDates, newEvent) => {
  return oldDates.map((oldDate) => ({
    ...oldDate,
    sessions: oldDate.sessions?.map((session) => ({
      ...session,
      events: session?.events.map((event) =>
        event.id === newEvent.id
          ? newEvent
          : {
              ...event,
              subEvents: event.subEvents.map((subEvent) =>
                subEvent.id === newEvent.id ? newEvent : { ...subEvent },
              ),
            },
      ),
    })),
  }));
};

const newMeetReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actionFulfilled(REMOVE_SESSION_AT_CREATE_MEET):
      return {
        ...state,
        meetDates: [
          ...state.meetDates.map((meetDate) => ({
            ...meetDate,
            sessions: meetDate.sessions
              .filter(({ id }) => id !== payload.sessionId)
              .map((session, index) => ({
                ...session,
                sessionNumber: index + 1,
              })),
          })),
        ],
      };
    case SET_JUDGES_SCHEMA_ID_ON_NEW_MEET:
      return {
        ...state,
        judgesSchemaId: payload,
      };
    case actionFulfilled(UPDATE_CREATED_EVENT):
      return {
        ...state,
        meetDates: replaceEvent(state.meetDates, payload),
      };
    case actionFulfilled(GET_TEAMS):
    case actionFulfilled(SEARCH_TEAMS): {
      const { currentPage, restTeams } = payload;
      const allTeams = !currentPage
        ? restTeams
        : [...state.restTeams, ...restTeams];

      return {
        ...state,
        restTeams: allTeams,
        teamsCurrentPage: currentPage,
      };
    }
    case SET_SELECTED_TEAMS:
    case actionFulfilled(GET_INVITED_TEAMS):
      return {
        ...state,
        intivedTeams: payload,
      };
    case CLEAR_SEARCH_TEAMS: {
      return {
        ...state,
        restTeams: [],
        teamsCurrentPage: 0,
      };
    }
    case actionFulfilled(SET_SELECTED_TEAMS):
      return {
        ...state,
        teams: payload,
        selectedTeams: payload,
      };
    case ON_GB_RESET_FOR_NEW_MEET:
      return {
        ...state,
        ...resetEventFields,
      };
    case RESET_EVENTS:
      return {
        ...state,
        meetDates: [],
        meetShort: {
          ...state.meetShort,
          days: [],
        },
      };
    case actionFulfilled(GET_AVAILABLET_ADMINS):
      const { admins, currentPage: adminsCurrentPage } = payload;
      const allUsers = !adminsCurrentPage
        ? admins
        : [...state.meetAdmins.allUsers, ...admins];

      return {
        ...state,
        meetAdmins: {
          ...state.meetAdmins,
          allUsers,
          adminsCurrentPage,
        },
      };

    case actionFulfilled(SET_ADMINS_FOR_MEET):
    case SET_ADMINS_FOR_MEET:
      return {
        ...state,
        meetAdmins: {
          ...state.meetAdmins,
          selectedAdmins: [...payload],
        },
      };
    case SET_NEW_MEET_START:
      return {
        ...state,
        startDate: payload,
      };
    case SET_NEW_MEET_END:
      return {
        ...state,
        endDate: payload,
      };
    case actionFulfilled(GET_AVAILABLE_GOVERNING_BODYES):
      return {
        ...state,
        availableGoverningBodyes: payload,
      };
    case actionFulfilled(CREATE_TEAM_VENUE_INIT):
      return {
        ...state,
        venueDTO: payload,
        teamVenues: [...state.teamVenues, payload],
      };
    case ADD_SEARCHED_VENUE_TO_LIST:
      return {
        ...state,
        teamVenues: [
          ...state.teamVenues.filter(({ searched }) => !searched),
          payload,
        ],
      };
    case actionFulfilled(GET_MEET_FOR_EDITING):
      return {
        ...state,
        ruleGroups: payload.ruleGroups,
        teamVenues: [payload.venue],
      };
    case actionFulfilled(GET_COUNTRY_ISO):
      return {
        ...state,
        countryISO: payload,
      };
    case actionFulfilled(CREATE_MEET_INIT):
      const {
        id: meetId,
        teams,
        governingBody: selectedGoverningBody,
        mainTeam,
        ruleGroups,
        teamVenues,
        allVenues,
        user,
      } = payload;

      return {
        ...state,
        meetId,
        teams,
        selectedGoverningBody,
        mainTeam,
        ruleGroups,
        meetAdmins: {
          ...state.meetAdmins,
          selectedAdmins: state.meetAdmins.selectedAdmins.some(
            ({ id }) => id === user.id,
          )
            ? [...state.meetAdmins.selectedAdmins]
            : [
                {
                  ...user,
                  title: `${user.firstName} ${user.lastName}`,
                  logoUrl: user.cloudAlias,
                },
                ...state.meetAdmins.selectedAdmins,
              ],
        },
        teamVenues,
        allVenues,
      };
    case actionFulfilled(UPDATE_TEAMS_IN_NEW_MEET):
      return {
        ...state,
        teamVenues: payload.teamVenues,
        teams: payload.teams,
      };
    case actionFulfilled(GET_TEAMS_VENUES):
      return {
        ...state,
        teamVenues: [
          ...state.teamVenues,
          ...payload.teamVenues.filter(
            ({ id }) => !state.teamVenues.some((venue) => venue.id === id),
          ),
        ],
      };
    case SET_MEET_DAYS:
      return {
        ...state,
        meetDates: payload,
      };
    case ADD_MEET_EVENTS: {
      const eventDailyIndex = state.meetDates.findIndex(
        ({ id }) => id === payload.dayId,
      );
      const eventDaily = state.meetDates[eventDailyIndex];
      const sessionIndex = eventDaily.sessions.findIndex(
        ({ id }) => id === payload.sessionId,
      );
      const session = eventDaily.sessions[sessionIndex];
      const changedEvents = [...session.events, ...payload.events];
      const changedSession = {
        ...session,
        events: changedEvents,
      };
      const changedSessions = [
        ...eventDaily.sessions.slice(0, sessionIndex),
        changedSession,
        ...eventDaily.sessions.slice(sessionIndex + 1),
      ];
      const changedEventDaily = {
        ...eventDaily,
        sessions: changedSessions,
      };
      const meetDatesWitnNewEvents = [
        ...state.meetDates.slice(0, eventDailyIndex),
        changedEventDaily,
        ...state.meetDates.slice(eventDailyIndex + 1),
      ];

      return {
        ...state,
        meetDates: meetDatesWitnNewEvents,
      };
    }
    case actionFulfilled(GET_NEW_MEET_JUDGES_BY_PAGE):
      const { currentPage: currentJudgesPage, usersDto = [] } = payload;
      const judges = !currentJudgesPage
        ? [...usersDto.map(judgeCallback)]
        : [...state.judges, ...usersDto.map(judgeCallback)];
      const selectedJudges = state.meetInfo.days
        .flatMap(({ sessions }) => sessions.flatMap(({ events }) => events))
        .find(({ judgePanel }) => judgePanel)?.judgePanel?.judges;

      return {
        ...state,
        judges: judges.map(({ status, ...judge }) => ({
          ...judge,
          status: selectedJudges?.some(
            ({ userInfo }) => userInfo.id === judge.id,
          )
            ? SWIPE_STATUS.INVITED
            : status,
        })),
        currentJudgesPage,
      };
    case actionFulfilled(GET_MEW_MEET_JUDGES_BY_SEARCH_KEY):
      return {
        ...state,
        judges: sliceJudges(state.judges, payload),
      };
    case UPDATE_JUDGE_STATUS:
      return {
        ...state,
        judges: state.judges.map((judge) =>
          judge.id === payload.id
            ? { ...judge, status: payload.status }
            : judge,
        ),
      };
    case CUT_EXTRA_INVITED_JUDGES:
      let invitedCounter = 0;
      const cutJudges = state.judges.map((judge) => {
        invitedCounter =
          judge.status === "INVITED" ? invitedCounter + 1 : invitedCounter;
        return {
          ...judge,
          status: invitedCounter <= payload ? judge.status : "NULL",
        };
      });
      return {
        ...state,
        judges: cutJudges,
      };
    case actionFulfilled(NEW_MEET_DND_EVENT_REPLACE):
    case actionFulfilled(NEW_MEET_DND_EVENT_COMBINE):
    case actionFulfilled(NEW_MEET_EVENT_UNCOMBINE):
    case actionFulfilled(NEW_MEET_EVENT_REORDER):
      if (!payload) return state;

      const oldEvents = state.meetDates
        ?.flatMap(({ sessions }) => sessions)
        ?.flatMap(({ events }) => events)
        ?.flatMap(({ subEvents = [], ...event }) => [event, ...subEvents]);

      const meetDates = state.meetDates.map((day) => ({
        ...day,
        sessions: day.sessions.map((session) => {
          const newSession = payload.days
            ?.flatMap(({ sessions }) => sessions)
            ?.find(({ id }) => id === session.id);

          if (!newSession) return session;

          return {
            ...session,
            events: newSession.events.map(({ subEvents = [], ...event }) => {
              const oldEventData = oldEvents.find(
                (oldEvent) => oldEvent.id === event.id,
              );

              const newSubEvents = subEvents.map((subEvent) => {
                const oldSubEventData = oldEvents.find(
                  (oldEvent) => oldEvent.id === subEvent.id,
                );

                if (oldSubEventData)
                  return {
                    ...oldSubEventData,
                    ...subEvent,
                  };

                return subEvent;
              });

              if (oldEventData)
                return {
                  ...oldEventData,
                  ...event,
                  subEvents: newSubEvents,
                };

              return {
                ...event,
                subEvents: newSubEvents,
              };
            }),
          };
        }),
      }));

      return {
        ...state,
        meetDates,
      };
    case SET_MEET_TYPE:
      return {
        ...state,
        meetType: payload,
      };
    case actionFulfilled(REMOVE_EVENT):
      const payloadDays = payload.days;
      const updatedMeetDays = state.meetDates.map((date) => {
        const dateFromServer =
          payloadDays.find((day) => day.id === date.id) || {};

        const updatedSessions =
          date?.sessions?.map((session) => {
            const sessionFromServer =
              dateFromServer?.sessions?.find(
                (serverSession) => serverSession.id === session.id,
              ) || {};
            const eventsFromServerIds =
              sessionFromServer.events?.map((event) => event.id) || [];

            return {
              ...session,
              events: session.events.filter((event) =>
                eventsFromServerIds.includes(event.id),
              ),
            };
          }) || [];

        return { ...date, sessions: updatedSessions };
      });

      return {
        ...state,
        meetDates: updatedMeetDays,
      };

    case SET_EVENT_ID_FOR_EDITING:
      return {
        ...state,
        eventIdForEditing: payload,
      };
    case CLEAR_EDITED_EVENT_ID:
      return {
        ...state,
        eventIdForEditing: "",
      };
    case CLEAR_NEW_MEET_DATA:
      return {
        ...initState,
      };
    case UPDATE_DAY_WARM_UP_TIME: {
      const { dayId, timeKey, hh, mm, isNotAm = false } = payload;

      return {
        ...state,
        meetDates: state.meetDates.map((day) => {
          if (day.id !== dayId) return { ...day };

          const timeData = day[timeKey] || {};
          const isAm = isNotAm ? !timeData.isAm : timeData.isAm;

          return {
            ...day,
            [timeKey]: {
              ...timeData,
              isAm,
              ISODate: getISODate({
                date: day.date,
                isAm,
                hh,
                mm,
              }),
            },
          };
        }),
      };
    }
    case UPDATE_SESSION_WARM_UP_TIME: {
      const { sessionId, timeKey, hh, mm, isNotAm = false } = payload;

      return {
        ...state,
        meetDates: state.meetDates.map((day) => {
          return {
            ...day,
            sessions: day.sessions.map((session) => {
              if (session.id !== sessionId) return { ...session };

              const timeData = session[timeKey] || {};
              const isAm = isNotAm ? !timeData.isAm : timeData.isAm;

              return {
                ...session,
                [timeKey]: {
                  ...timeData,
                  isAm,
                  ISODate: getISODate({
                    date: day.date,
                    isAm,
                    hh,
                    mm,
                  }),
                },
              };
            }),
          };
        }),
      };
    }
    case actionFulfilled(SET_JUDGES):
    case SET_MEET_INFO: {
      return {
        ...state,
        meetInfo: payload,
      };
    }
    default:
      return state;
  }
};

export default newMeetReducer;
